<!-- 核销码信息 -->
<template>
	<div class="my-orders-detail">
		<div class="headerTop">

		</div>
		<div class="order-content">
			<!--  -->
			<div class="orderInfo">
				<div v-for="(item,index) in orderDetail.products" class="goods-item">
					<img :src="item.image" alt="">
					<div
						style="height: 80px;display: flex;justify-content: space-between;flex-direction: column;width: calc(100% - 105px);">
						<div class="detailTitle">{{item.name}}</div>
						<div class="countUnit">
							<p> <span style="font-size: 16px;color: red;"><span
										style="font-size: 12px;">￥</span>{{item.price}}</span> <span
									style="font-size: 12px;">{{item.unit}}</span>
							</p>
							<p>x{{item.count}}</p>
						</div>
					</div>
				</div>
			</div>
			<!--  -->
			<div class="numOrder">
				<div class="infoNum">订单信息</div>
				<div class="listInfo">
					<p>订单编号: <span>{{orderDetail.order_num}}</span></p>
					<!-- <p>购买数量: <span>{{orderDetail.count}}</span></p> -->
					<p>订单金额: <span>{{orderDetail.money}}</span></p>
					<p>订单状态:
						<span v-if="orderDetail.pay_status == 0">待支付</span>
						<span v-if="orderDetail.pay_status == 1">已支付</span>
						<span v-if="orderDetail.pay_status == 2">订单已取消</span>
						<span v-if="orderDetail.pay_status == -1">已退款</span>
					</p>
					<p>订单时间: <span>{{orderDetail.create_time}}</span></p>
				</div>
			</div>
			<div class="clientInfo">
				<div class="infoNum">客户信息</div>
				<div class="listInfo">
					<p>下单人姓名: <span>{{orderDetail.name}}</span></p>
					<p>下单人手机号: <span>{{orderDetail.tel}}</span></p>
				</div>
			</div>
		</div>
		<div class="footerBott">
			<div>
				<button v-if="orderDetail.take_status == 0" class="not" @click="writeOff">核销</button>
				<button v-else class="already">已核销</button>
				<!-- <button v-if="orderDetail.pay_status != 2" @click="cancelOrder(orderDetail.id)">取消订单</button>
				<button v-if="orderDetail.pay_status == 0" @click="getTN(orderDetail.order_num)">待支付</button>
				<button v-if="orderDetail.pay_status == 1">已支付</button> -->
			</div>
			<div>

			</div>
		</div>

	</div>
</template>

<script>
	import {
		getQrcodeInfo,
		getCheck
	} from '@/api/order'
	export default {
		inject: ['reload'],
		data() {
			return {
				orderDetail: {},
			}
		},
		mounted() {
			console.log(this.$route.query.code)
			this.getQrcodeInfo()
		},
		methods: {
			writeOff() {
				getCheck({
					code: this.$route.query.code
				}).then(res => {
					console.log(res)
					if (res.code == 200) {
						this.$toast('核销成功')
						this.reload();
					} else {
						this.$toast(res.msg)
					}
				})
			},
			getQrcodeInfo() {
				let params = {
					code:this.$route.query.code
					// code: 'kLRTUhzB+vBt254RjxdjSx2N/RGhNZpkGRBZ+ZCS4bc='
				}
				getQrcodeInfo(params).then((res) => {
					console.log(res)
					this.orderDetail = res.data
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.my-orders-detail {
		// padding: 15px;
		// padding-bottom: 50px;
		background-color: #f6f6f6;

		.headerTop {
			width: 100%;
			height: 2.5rem;
			background-color: #FC6228;
		}


		.order-content {
			padding: 15px;
			// margin-top: 15px;
			// background-color: #FFFFFF;
			// box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
			border-radius: 5px;


			// background-color: #f6f6f6;
position: relative;
top: -2.3rem;
			width: 100%;
			padding-bottom: 50px;
			box-sizing: border-box;

			.orderInfo {
				width: 100%;
				margin-bottom: 0.15rem;
				font-size: 0.2rem;

				box-sizing: border-box;

				.goods-item {
					display: flex;
					background-color: #FFFFFF;
					margin-bottom: 5px;
						padding:  10px;
					border-radius: 5px;

					img {
						width: 80px;
						height: 80px;
						border-radius: 5px;
						margin-right: 15px;
					}

					.detailTitle {
						font-size: 14px;
					}

					.countUnit {
						font-size: 0.18rem;
						display: flex;
						justify-content: space-between;
						align-items: center;

						p {
							margin: 0;
						}
					}

					.useTime {
						font-size: 0.2rem;
						color: red;

						span {
							color: #000;
						}
					}
				}

			}

			.numOrder {
				width: 100%;
				box-sizing: border-box;
				// height: 2.6rem;
				background: #FFFFFF;
				border-radius: 5px;
				margin-bottom: 0.15rem;
				font-size: 0.2rem;
				padding: 15px;

				.infoNum {
					font-size: 14px;
					font-weight: 500;
					color: #333333;
				}

				.listInfo {
					margin-left: 0.2rem;

					p {
						margin: 0;
						font-size: 12px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}

			.clientInfo {
				width: 100%;
				box-sizing: border-box;
				// height: 2.6rem;
				background: #FFFFFF;
				border-radius: 5px;
				margin-bottom: 0.15rem;
				font-size: 0.2rem;
				padding: 15px;

				.infoNum {
					font-size: 14px;
					font-weight: 500;
					color: #333333;
				}

				.listInfo {
					margin-left: 0.2rem;

					p {
						margin: 0;
						font-size: 12px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}

			.noticeInfo {
				width: 93%;
				// height: 2.6rem;
				background: #FFFFFF;
				border-radius: 20px;
				margin-bottom: 50px;
				font-size: 0.2rem;
				padding: 15px;
				font-size: 0.22rem;
				font-weight: 500;

				.fkm {
					margin-top: 15px;
					display: flex;
					align-items: center;
					justify-content: center;
					// flex-direction: column;
				}
			}


		}

		.footerBott {
			width: 100%;
			height: 51px;
			background: #FFFFFF;
			position: fixed;
			bottom: 0;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.not {
				width: 87px;
				height: 34px;
				background: #FC6228;
				border-radius: 5px;
				border: none;
				color: #FFFFFF;
				margin-right: 10px;
			}

			.already {
				width: 87px;
				height: 34px;
				background: #999999;
				border-radius: 5px;
				border: none;
				color: #FFFFFF;
				margin-right: 10px;
			}
		}
	}
</style>
